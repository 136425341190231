.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
}

.marquee-content {
    display: flex;
    animation: marquee 12s linear infinite;
}

.marquee-item {
    flex: none;
    padding: 0 2rem;
    font-size: 6rem;
    font-weight: 900;
    letter-spacing: 4px;
}

.solid-text {
    background: linear-gradient(90deg, #F89B29, #FF5733, #FF8D1A);
    /* Define your gradient colors */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.border-text {
    color: transparent;
    -webkit-text-stroke: 1px #F89B29;
    text-stroke: 1px #F89B29;
}


/* .solid-text {
    color: #F89B29;
} */

/* .border-text {
    color: transparent;
    -webkit-text-stroke: 1px #F89B29;
    text-stroke: 1px #F89B29;
} */

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@media (max-width: 1200px) {
    .marquee-item {
        font-size: 6.8rem;
    }
}

@media (max-width: 992px) {
    .marquee-item {
        font-size: 6.8rem
    }
}

@media (max-width: 768px) {
    .marquee-item {
        font-size: 6.8rem;
    }
}

@media (max-width: 576px) {
    .marquee-item {
        font-size: 6.8rem;
    }
}

.custom-cursor {
    width: 25px;
    height: 25px;
    background-color: #000000;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

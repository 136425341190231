@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Exo", sans-serif;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #360208 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #bebfc0;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #360208;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #fe8740;
}

#element {
    overflow: scroll;
    scrollbar-width: none; 
   
  }

  #element::-webkit-scrollbar {
    display: none;
  }